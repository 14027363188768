import { 
	Component, 
	OnInit
} from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router'

import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

import { AppSettings } from './app-settings';

import { CourseService } from './services/course.service';
import { TeamService } from './services/team.service';
import { UserService } from './services/user.service';

import { Course } from './models/course';
import { Team } from './models/team';
import { CourseSubscription } from './models/course-subscription';
import { TeamMembership } from './models/team-membership';

@Component({
	selector: 'manage-team',
	templateUrl: './manage-team.component.html',
	styleUrls: ['./manage-team.component.css']
})
export class ManageTeamComponent implements OnInit {

	private APP_SETTINGS = AppSettings;

	private team: Team;
	private courses: Array<Course>;

	private team_name_control = new FormControl('', [Validators.required]);
	private editTeamForm = new FormGroup({
		name: this.team_name_control
	});

	private new_member_email_control = new FormControl('', [Validators.required, Validators.email]);
	private addMemberForm = new FormGroup({
		member_email: this.new_member_email_control
	});

	private waitingForServerResponse = false;

	constructor(
		private courseService: CourseService, 
		private route: ActivatedRoute, 
		private router: Router, 
		private teamService: TeamService, 
		private userService: UserService
	) {
		if (this.userService.getLoggedInUser() == null) {
			this.router.navigate(["/"]);
			return;
		}
	}

	ngOnInit(): void {
		this.route.params.subscribe(params => {
			var team_id = +params['id'];
			if (team_id) {
				this.teamService.getTeamAsCoach(team_id).subscribe(
					team => {
						this.team = team;
					}, 
					error => {
						// Do nothing.
					}
				);
			}
		});

		this.courseService.getCoursesAsCoach().subscribe(
			courses => {
				this.courses = courses;
			}, 
			error => {
				// Do nothing.
			}
		);
	}

	private getErrorMessage_team_name(): string {
		return this.team_name_control.hasError('required') ? 'You must enter a value' : '';
	}

	private getErrorMessage_new_member_email(): string {
		return this.new_member_email_control.hasError('required') ? 'You must enter a value' : this.new_member_email_control.hasError('email') ? 'Not a valid email' : '';
	}

	private getCoursesAvailableForSubscription(): Array<Course> {
		let availableCourses = new Array<Course>();
		for (var i = 0; i < this.courses.length; i ++) {
			let course = this.courses[i];
			let available = true;
			for (var j = 0; j < this.team.course_subscriptions.length; j ++) {
				if (course.id == this.team.course_subscriptions[j].course_id) {
					available = false;
					break;
				}
			}

			if (available) availableCourses.push(course);
		}

		return availableCourses;
	}

	private subscribe(course: Course): void {
		this.waitingForServerResponse = true;
		this.teamService.createCourseSubscription(this.team.id, course.id).subscribe(
			subscription => {
				this.waitingForServerResponse = false;
				this.team.course_subscriptions.push(subscription);
			}, 
			error => {
				this.waitingForServerResponse = false;
			}
		);
	}

	private unsubscribe(subscription: CourseSubscription): void {
		this.waitingForServerResponse = true;
		this.teamService.deleteCourseSubscription(subscription).subscribe(
			empty => {
				this.waitingForServerResponse = false;
				for (var i = 0; i < this.team.course_subscriptions.length; i ++) {
					if (this.team.course_subscriptions[i].id == subscription.id) {
						this.team.course_subscriptions.splice(i, 1);
						break;
					}
				}
			}, 
			error => {
				this.waitingForServerResponse = false;
			}
		);
	}

	private submitEditTeamForm(): void {
		this.waitingForServerResponse = true;
		this.teamService.editTeam(this.team).subscribe(
			team => {
				this.waitingForServerResponse = false;
				this.team = team;
			}, 
			error => {
				this.waitingForServerResponse = false;
			}
		);
	}

	private submitAddMemberForm(): void {
		this.waitingForServerResponse = true;
		this.teamService.addOrInviteMemberByEmail(this.team.id, this.new_member_email_control.value).subscribe(
			membership => {
				this.waitingForServerResponse = false;
				if (membership == null) {
					alert("We couldn't find that user, but we have sent an invitation to join your team.");
				} else {
					this.team.memberships.push(membership);
					this.team.memberships.sort(function(a, b) {
					    if (a.member_lname < b.member_lname) { return -1; }
					    if (a.member_lname > b.member_lname) { return 1; }
					    return 0;
					});
				}
			}, 
			error => {
				this.waitingForServerResponse = false;
			}
		);
	}

	private deleteMembership(membership: TeamMembership): void {
		if (!confirm("Are you sure you wish to remove this team member?")) return;

		this.waitingForServerResponse = true;
		this.teamService.deleteMembership(membership).subscribe(
			empty => {
				this.waitingForServerResponse = false;
				for (var i = 0; i < this.team.memberships.length; i ++) {
					if (this.team.memberships[i].id == membership.id) {
						this.team.memberships.splice(i, 1);
						break;
					}
				}
			}, 
			error => {
				this.waitingForServerResponse = false;
			}
		);
	}

}


