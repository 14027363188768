import { AssessmentItem } from './assessment-item';

export class Course {
    id: number;
    name: string;
    assessment_items: Array<AssessmentItem>;

  	constructor(
        id: number, 
        name: string, 
        assessment_items: Array<AssessmentItem>, 
    ) {
        this.id = id;
        this.name = name;
        this.assessment_items = assessment_items;
    }

}


