import { 
	Component, 
	ElementRef, 
	HostListener, 
	OnInit, 
	ViewChild
} from '@angular/core';

import { Router } from '@angular/router'

import { DomSanitizer, SafeStyle } from '@angular/platform-browser'

import { trigger, state, style, animate, transition } from '@angular/animations';

import { AppSettings } from './app-settings';

import { ModuleService } from './services/module.service';
import { RatingService } from './services/rating.service';
import { TestService } from './services/test.service';
import { UserService } from './services/user.service';

import { Module } from './models/module';
import { Rating } from './models/rating';

@Component({
	selector: 'dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.css'], 
	animations: [
		trigger('prismState_container', [
	    	state('0_turns', style({ transform: 'rotate(0)' })), 
	    	state('1_turns', style({ transform: 'rotate(360deg)' })), 
	    	state('2_turns', style({ transform: 'rotate(720deg)' })), 
			state('3_turns', style({ transform: 'rotate(1080deg)' })), 
			transition('* => *', animate('500ms ease-out')), 
	    ]), 
	    trigger('fadeIn_number', [
	    	state('hide', style({ opacity: 0 })), 
	    	state('show', style({ opacity: 1.0 })), 
			transition('hide => show', animate('500ms linear')), 
	    ]),
	    trigger('fadeIn_container', [
	    	state('hide', style({ opacity: 0 })), 
	    	state('show', style({ opacity: 1.0 })), 
			transition('hide => show', animate('500ms linear')), 
	    ]), 
	    trigger('prismState_outer', [
	    	state('module', style({ transform: 'rotate(0)' })),
			state('rpc', style({ transform: 'rotate(120deg)' })),
			state('profile', style({ transform: 'rotate(240deg)' })),
			transition('* => *', animate('800ms ease-in-out')),
	    ]), 
	    trigger('prismState_inner', [
	    	state('module', style({ transform: 'rotate(0)' })),
			state('rpc', style({ transform: 'rotate(-120deg)' })),
			state('profile', style({ transform: 'rotate(-240deg)' })),
			transition('* => *', animate('800ms ease-in-out')),
	    ]), 
	    trigger('prismButtonState', [
	    	state('bottom', style({ top: "calc(100% + 35px)", left: '50%' })),
			state('top_left', style({ top: '50px', left: 'calc(50% - ' + 80 + 'px)' })),
			state('top_right', style({ top: '50px', left: 'calc(50% + ' + 80 + 'px)'})),
			transition('* => *', animate('800ms ease-in-out')),
	    ]), 
	    trigger('descriptionState', [
			state('visible', style({height: 'auto',marginBottom: '20px', lineHeight: '22px'})),
			state('void', style({height: '0px', marginBottom: '0px', lineHeight: '0px'})),
			transition('void => *', animate('500ms ease-out')), 
			transition('* => void', animate('500ms ease-in'))
	    ]), 
	]
})
export class DashboardComponent implements OnInit {

	private APP_SETTINGS = AppSettings;

	private TRIANGLE_SECTOR_COLOR_CUTOFF: number = 7.5;
	private TRIANGLE_SECTOR_FILL_COLOR: string = "limegreen";

	private ANIMATION_DELAY: number = 20;

	private POINT_DOT_CIRCLE_RADIUS: number = 7;

	private modules: Array<Module>;
	private assessment_items_flat: Array<any> = new Array<any>();
	private active_module_id: number = -1;
	private active_module_name: string = null;
	private active_module_boundary_index: number = -1;
	private active_assessment_item: any = null;
	private ratings: Array<Rating>;

	private svn_report: any;
	private personality_facets_flat: Array<any> = new Array<any>();
	private active_personality_facet: any;

	private view_type: string = "module";
	private should_draw_prism_buttons: boolean = false;

	private animation_cycle_index: number = 0;
	private rating_animation_index: number = -1;

	private prism_container_state: string = "0_turns";
	private fadeIn_number_state: string = "show";
	private fadeIn_container_state: string = "show";

	@ViewChild("prism_container", {static: false}) prism_container: ElementRef;
	@ViewChild("prism_outer", {static: false}) prism_outer: ElementRef;
	@ViewChild("prism_inner", {static: false}) prism_inner: ElementRef;

	@HostListener("window:click", ['$event.target']) 
    private onClick(target) {
    	if (target.className.indexOf("assessment") == -1 && target.tagName.indexOf("MAT-ICON") == -1) {
    		this.active_module_id = -1;
    		this.active_module_name = null;
    		this.active_assessment_item = null;
    		this.active_module_boundary_index = -1;
    	}
    }

	@HostListener("window:resize", ['$event']) 
    private onWindowResize(event) {
    	this.draw_prism();
    }

	constructor(
		private moduleService: ModuleService, 
		private ratingService: RatingService, 
		private router: Router, 
		private sanitizer: DomSanitizer, 
		private testService: TestService, 
		private userService: UserService
	) {
		if (this.userService.getLoggedInUser() == null) {
			this.router.navigate(["/"]);
			return;
		}
	}

	ngOnInit(): void {
		this.moduleService.getModules().subscribe(
			res => {
				this.modules = res;
				this.modules.forEach(module => {
					module.assessment_items_flat.forEach(item => {
						item.self_ratings = [];
						item.rpc_ratings = [];
						item.current_rating = 0;
						item.module_id = module.id;
						item.module_name = module.name;
						item.is_module_boundary = false;
						// item.score = Math.floor(Math.random() * 5) + 1;
					});
					this.assessment_items_flat.push({
						module_id: module.id, 
						module_name: module.name, 
						is_module_boundary: true, 
					});
					this.assessment_items_flat = this.assessment_items_flat.concat(module.assessment_items_flat);
				});

				this.ratingService.getAllUserRatings().subscribe(
					res => {
						this.ratings = res.ratings;
						this.animate_ratings_module(this.animation_cycle_index);
					}, 
					error => {
						// Do nothing.
					}
				);
			}, 
			error => {
				// Do nothing.
			}
		);
 
		this.testService.generateSVNReport().subscribe(
			svn_report => {
				if (svn_report == null) {
					this.svn_report = {categories: []};
				} else {
					this.svn_report = svn_report;
					this.svn_report.categories.forEach(category => {
						category.facets.forEach(facet => {
							facet.css_class = AppSettings.getCSSClassForFacet(facet);
							facet.color = AppSettings.getColorForFacet(facet);
							this.personality_facets_flat.push(facet);
						});
					});
				}
			}, 
			error => {
				// Do nothing for now.
			}
		);

		setTimeout(() => { this.draw_prism(); this.should_draw_prism_buttons = true; }, 0);
	}

	private get_point_dot_class(item: any, point_dot_index: number): string {
		return (point_dot_index < Math.round(item.score * item.current_rating / 10)) ? "point_dot_full" : "point_dot_empty";
	}
	private generate_point_dot_array(max_points: number): Array<number> {
		return Array(max_points);
	}
	private generate_point_dot_transform(max_points: number, point_dot_index: number): SafeStyle {
		if (max_points == 1) return "";
		let x = Math.round(Math.cos(3 * Math.PI / 2 + point_dot_index / max_points * 2 * Math.PI) * this.POINT_DOT_CIRCLE_RADIUS * 10) / 10;
		let y = Math.round(Math.sin(3 * Math.PI / 2 + point_dot_index / max_points * 2 * Math.PI) * this.POINT_DOT_CIRCLE_RADIUS * 10) / 10;
		return this.sanitizer.bypassSecurityTrustStyle("translateX(" + x + "px) translateY(" + y + "px)");
	}

	private format_personality_score(score: number): number {
		return Math.round(score);
	}

	private activate_module_boundary(assessment_item_index: number): void {
		this.active_assessment_item = null;

		let assessment_item = this.assessment_items_flat[assessment_item_index];
		this.active_module_id = assessment_item.module_id;
		this.active_module_name = assessment_item.module_name;
		this.active_module_boundary_index = assessment_item_index;

		this.play_slots();
	}

	private activate_assessment_item(assessment_item: any): void {
		this.active_assessment_item = assessment_item;
		this.active_module_id = assessment_item.module_id;
		this.active_module_name = assessment_item.module_name;
		this.active_module_boundary_index = -1;

		this.play_slots();
	}

	private activate_personality_facet(facet: any): void {
		this.active_personality_facet = facet;

		this.play_slots();
	}

	private play_slots(): void {
		this.draw_prism();
		if (this.prism_container_state == "0_turns") {
			this.prism_container_state = "1_turns";
		} else {
			this.prism_container_state = "0_turns";
		}
		if (this.active_personality_facet) {
			this.fadeIn_container_state = "hide";
			setTimeout(() => { this.fadeIn_container_state = "show"; }, 100);
		} else {
			this.fadeIn_number_state = "hide";
			setTimeout(() => { this.fadeIn_number_state = "show"; }, 100);
		}
	}

	private santize_for_prism(x: number): string {
		return Math.round(x).toString();
	}

	// Returns 0 iff no ratings:
	private compute_average_rating(ratings: Array<any>, num_ratings_to_average: number): number {
		var sum_ratings = 0;
		var num_ratings = 0;
		for (var i = 0; i < Math.min(num_ratings_to_average, ratings.length); i ++) {
			sum_ratings += ratings[ratings.length - 1 - i].rating;
			num_ratings ++;
		}

		if (num_ratings == 0) {
			return 0;
		} else {
			return sum_ratings / num_ratings;
		}
	}

	private compute_opacity_for_assessment_item(assessment_item: any): any {
		if (this.active_module_id > -1 && assessment_item.module_id != this.active_module_id) return 0.4;
		return 1.0;
	}

	private should_display_module_boundary(i: number): boolean {
		// First check to see if we are on a boundary:
		if (i == 0 || this.assessment_items_flat[i - 1].module_id != this.assessment_items_flat[i].module_id) {
			// Now check to see if we display at least one item in this module:
			for (var j = i + 1; j < this.assessment_items_flat.length; j ++) {
				if (this.assessment_items_flat[i].module_id != this.assessment_items_flat[j].module_id) return false;
				if (this.assessment_items_flat[j].score >= 0 && !this.assessment_items_flat[j].hidden) return true;
			}
			return false;
		} else {
			return false;
		}
	}

	// View without points:
	private compute_display_score_for_module_boundary(i: number): any {
		var to_return = Math.round(this.compute_score_for_module_boundary(i) * 10.0);
		if (to_return == 0) return "?";
		return to_return;
	}
	private compute_score_for_module_boundary(i: number): number {
		var current_points = 0;
		var possible_points = 0;
		for (var j = i + 1; j < this.assessment_items_flat.length; j ++) {
			if (this.assessment_items_flat[i].module_id != this.assessment_items_flat[j].module_id) break;
			if (this.assessment_items_flat[j].hidden) continue;
			if (this.assessment_items_flat[j].score < 0) continue;
			if (this.assessment_items_flat[j].current_rating > 0) {
				current_points += this.assessment_items_flat[j].score * this.assessment_items_flat[j].current_rating / 10.0;
				possible_points += this.assessment_items_flat[j].score;
			}
		}
		if (possible_points == 0) return 0;
		return current_points / possible_points;
	}

	// View with points:
	// private compute_display_score_for_module_boundary(i: number): any {
	// 	return this.compute_score_for_module_boundary(i);
	// }
	// private compute_score_for_module_boundary(i: number): number {
	// 	var current_points = 0;
	// 	var possible_points = 0;
	// 	for (var j = i + 1; j < this.assessment_items_flat.length; j ++) {
	// 		if (this.assessment_items_flat[i].module_id != this.assessment_items_flat[j].module_id) break;
	// 		if (this.assessment_items_flat[j].score <= 0) continue;

	// 		let item = this.assessment_items_flat[j];
	// 		current_points += Math.round(item.score * item.current_rating / 10)
	// 		possible_points += item.score;
	// 	}
	// 	return current_points;
	// }
	// private compute_height_for_module_boundary(i: number): string {
	// 	var current_points = 0;
	// 	var possible_points = 0;
	// 	for (var j = i + 1; j < this.assessment_items_flat.length; j ++) {
	// 		if (this.assessment_items_flat[i].module_id != this.assessment_items_flat[j].module_id) break;
	// 		if (this.assessment_items_flat[j].score <= 0) continue;

	// 		let item = this.assessment_items_flat[j];
	// 		current_points += Math.round(item.score * item.current_rating / 10)
	// 		possible_points += item.score;
	// 	}
	// 	return (Math.round(current_points / possible_points * 25 * 10) / 10) + "px";
	// }

	private should_display_module_boundary_for_rpc(i: number): boolean {
		// First check to see if we are on a boundary:
		if (i == 0 || this.assessment_items_flat[i - 1].module_id != this.assessment_items_flat[i].module_id) {
			// Now check to see if we display at least one item in this module:
			for (var j = i + 1; j < this.assessment_items_flat.length; j ++) {
				if (this.assessment_items_flat[i].module_id != this.assessment_items_flat[j].module_id) return false;
				if (this.assessment_items_flat[j].rpc && !this.assessment_items_flat[j].hidden) return true;
			}
			return false;
		} else {
			return false;
		}
	}

	private compute_display_score_for_module_boundary_for_rpc(i: number): any {
		var to_return = Math.round(this.compute_score_for_module_boundary_for_rpc(i) * 10.0);
		if (to_return == 0) return "?";
		return to_return;
	}
	private compute_score_for_module_boundary_for_rpc(i: number): number {
		var current_points = 0;
		var possible_points = 0;
		for (var j = i + 1; j < this.assessment_items_flat.length; j ++) {
			if (this.assessment_items_flat[i].module_id != this.assessment_items_flat[j].module_id) break;
			if (this.assessment_items_flat[j].hidden) continue;
			if (!this.assessment_items_flat[j].rpc) continue;
			if (this.assessment_items_flat[j].current_rating > 0) {
				current_points += this.assessment_items_flat[j].score * this.assessment_items_flat[j].current_rating / 10.0;
				possible_points += this.assessment_items_flat[j].score;
			}
		}
		if (possible_points == 0) return 0;
		return current_points / possible_points;
	}

	private getViewText(): string {
		if (this.view_type == "module") {
			return "Strategy";
		} else if (this.view_type == "rpc") {
			// return "Role-plays";
			return "Role Practice";
		} else if (this.view_type == "profile") {
			return "Profile";
		}
	}

	private computePrismButtonState(_view_type: string): string {
		if (this.view_type == "module") {
			if (_view_type == "module") {
				return "bottom";
			} else if (_view_type == "rpc") {
				return "top_left";
			} else if (_view_type == "profile") {
				return "top_right";
			}
		} else if (this.view_type == "rpc") {
			if (_view_type == "module") {
				return "top_right";
			} else if (_view_type == "rpc") {
				return "bottom";
			} else if (_view_type == "profile") {
				return "top_left";
			}
		} else if (this.view_type == "profile") {
			if (_view_type == "module") {
				return "top_left";
			} else if (_view_type == "rpc") {
				return "top_right";
			} else if (_view_type == "profile") {
				return "bottom";
			}
		}
	}

	private CANVAS_MARGIN: number = 5;
	private CANVAS_MAX_HEIGHT: number = 150;
	private CANVAS_STROKE_STYLE: string = "#9BD0FD";
	private CANVAS_STROKE_LINE_WIDTH: number = 3;
	private canvas_width: number;
	private canvas_height: number;
	private draw_prism(): void {
		this.canvas_width = this.prism_container.nativeElement.offsetWidth;
		let center_x = this.canvas_width / 2;
		this.canvas_height = Math.min(center_x, this.CANVAS_MAX_HEIGHT);
		this.prism_outer.nativeElement.width = this.canvas_width;
		this.prism_outer.nativeElement.height = this.canvas_height;
		this.prism_inner.nativeElement.width = this.canvas_width;
		this.prism_inner.nativeElement.height = this.canvas_height;

		// Set transformOrigin to the centroid:
		this.prism_container.nativeElement.style.transformOrigin = center_x + "px " + ((2 * this.canvas_height - this.CANVAS_MARGIN) / 3) + "px";
		this.prism_outer.nativeElement.style.transformOrigin = center_x + "px " + ((2 * this.canvas_height - this.CANVAS_MARGIN) / 3) + "px";
		this.prism_inner.nativeElement.style.transformOrigin = center_x + "px " + ((2 * this.canvas_height - this.CANVAS_MARGIN) / 3) + "px";

		let outer_triangle_top_y = this.CANVAS_MARGIN;
		let outer_triangle_bottom_y = this.canvas_height - this.CANVAS_MARGIN;
		let outer_triangle_delta_x = Math.tan(Math.PI / 6) * (this.canvas_height - 2 * this.CANVAS_MARGIN);

		let canvas_outer = this.prism_outer.nativeElement;
		let ctx_outer = canvas_outer.getContext("2d");
		ctx_outer.clearRect(0, 0, canvas_outer.width, canvas_outer.height);
		ctx_outer.strokeStyle = this.CANVAS_STROKE_STYLE;
		ctx_outer.lineWidth = this.CANVAS_STROKE_LINE_WIDTH;

		let canvas_inner = this.prism_inner.nativeElement;
		let ctx_inner = canvas_inner.getContext("2d");
		ctx_inner.clearRect(0, 0, canvas_inner.width, canvas_inner.height);
		ctx_inner.strokeStyle = this.CANVAS_STROKE_STYLE;
		ctx_inner.lineWidth = this.CANVAS_STROKE_LINE_WIDTH;

		// Draw the outer triangle:
		ctx_outer.beginPath();
		ctx_outer.moveTo(center_x, outer_triangle_top_y);
		ctx_outer.lineTo(center_x + outer_triangle_delta_x, outer_triangle_bottom_y);
		ctx_outer.lineTo(center_x - outer_triangle_delta_x, outer_triangle_bottom_y);
		ctx_outer.lineTo(center_x, outer_triangle_top_y);
		ctx_outer.stroke();

		// Draw the inner triangle:
		let inner_triangle_correction = 1.5;
		ctx_inner.beginPath();
		ctx_inner.moveTo(center_x, outer_triangle_bottom_y - inner_triangle_correction);
		ctx_inner.lineTo(center_x + outer_triangle_delta_x / 2 - inner_triangle_correction, this.canvas_height / 2);
		ctx_inner.lineTo(center_x - outer_triangle_delta_x / 2 + inner_triangle_correction, this.canvas_height / 2);
		ctx_inner.lineTo(center_x, outer_triangle_bottom_y - inner_triangle_correction);
		ctx_inner.stroke();
		if (this.active_personality_facet) {
			ctx_inner.fillStyle = this.active_personality_facet.color;
			ctx_inner.fill();
		}

		// Lower-left self-rating triangle:
		var draw_lower_left = false;
		if (this.ratings) {

			if (this.active_assessment_item) {

				if (this.compute_average_rating(this.active_assessment_item.self_ratings, AppSettings.SELF_MOVING_AVERAGE_CONSTANT) >= this.TRIANGLE_SECTOR_COLOR_CUTOFF) draw_lower_left = true;

			} else if (this.active_module_boundary_index > -1) {

				var sum_averages = 0;
				var num_averages = 0;
				for (var assessment_item_index = 0; assessment_item_index < this.assessment_items_flat.length; assessment_item_index ++) {
					let item = this.assessment_items_flat[assessment_item_index];
					if (item.module_id != this.active_module_id) continue;
					if (item.hidden) continue;
					if (item.score < 0) continue;
					if (item.is_module_boundary) continue;
					if (item.self_ratings.length > 0) {
						sum_averages += this.compute_average_rating(item.self_ratings, AppSettings.SELF_MOVING_AVERAGE_CONSTANT);
						num_averages ++;
					}
				}
				if (num_averages > 0 && sum_averages / num_averages >= this.TRIANGLE_SECTOR_COLOR_CUTOFF) draw_lower_left = true;

			}

		}
		if (draw_lower_left) {
			ctx_outer.beginPath();
			if (this.view_type == "module") {
				ctx_outer.moveTo(center_x - outer_triangle_delta_x / 2 + inner_triangle_correction, this.canvas_height / 2);
				ctx_outer.lineTo(center_x, outer_triangle_bottom_y - inner_triangle_correction);
				ctx_outer.lineTo(center_x - outer_triangle_delta_x, outer_triangle_bottom_y);
				ctx_outer.lineTo(center_x - outer_triangle_delta_x / 2 + inner_triangle_correction, this.canvas_height / 2);
			} else if (this.view_type == "rpc") {
				ctx_outer.moveTo(center_x + outer_triangle_delta_x / 2 - inner_triangle_correction, this.canvas_height / 2);
				ctx_outer.lineTo(center_x, outer_triangle_bottom_y - inner_triangle_correction);
				ctx_outer.lineTo(center_x + outer_triangle_delta_x, outer_triangle_bottom_y);
				ctx_outer.lineTo(center_x + outer_triangle_delta_x / 2 - inner_triangle_correction, this.canvas_height / 2);
			}
			ctx_outer.fillStyle = this.TRIANGLE_SECTOR_FILL_COLOR;
			ctx_outer.fill();
		}

		// Lower-right rpc triangle:
		var draw_lower_right = false;
		if (this.ratings) {

			if (this.active_assessment_item) {

				if (this.compute_average_rating(this.active_assessment_item.rpc_ratings, AppSettings.RPC_MOVING_AVERAGE_CONSTANT) >= this.TRIANGLE_SECTOR_COLOR_CUTOFF) draw_lower_right = true;

			} else if (this.active_module_boundary_index > -1) {

				var sum_averages = 0;
				var num_averages = 0;
				for (var assessment_item_index = 0; assessment_item_index < this.assessment_items_flat.length; assessment_item_index ++) {
					let item = this.assessment_items_flat[assessment_item_index];
					if (item.module_id != this.active_module_id) continue;
					if (item.hidden) continue;
					if (!item.rpc) continue;
					if (item.is_module_boundary) continue;
					if (item.rpc_ratings.length > 0) {
						sum_averages += this.compute_average_rating(item.rpc_ratings, AppSettings.SELF_MOVING_AVERAGE_CONSTANT);
						num_averages ++;
					}
				}
				if (num_averages > 0 && sum_averages / num_averages >= this.TRIANGLE_SECTOR_COLOR_CUTOFF) draw_lower_right = true;

			}

		}
		if (draw_lower_right) {
			ctx_outer.beginPath();
			if (this.view_type == "module") {
				ctx_outer.moveTo(center_x + outer_triangle_delta_x / 2 - inner_triangle_correction, this.canvas_height / 2);
				ctx_outer.lineTo(center_x, outer_triangle_bottom_y - inner_triangle_correction);
				ctx_outer.lineTo(center_x + outer_triangle_delta_x, outer_triangle_bottom_y);
				ctx_outer.lineTo(center_x + outer_triangle_delta_x / 2 - inner_triangle_correction, this.canvas_height / 2);
			} else if (this.view_type == "rpc") {
				ctx_outer.moveTo(center_x, outer_triangle_top_y);
				ctx_outer.lineTo(center_x - outer_triangle_delta_x / 2 + inner_triangle_correction, this.canvas_height / 2);
				ctx_outer.lineTo(center_x + outer_triangle_delta_x / 2 - inner_triangle_correction, this.canvas_height / 2);
				ctx_outer.lineTo(center_x, outer_triangle_top_y);
			}
			ctx_outer.fillStyle = this.TRIANGLE_SECTOR_FILL_COLOR;
			ctx_outer.fill();
		}

		var draw_top = false;
		if (this.ratings) {

			if (this.active_assessment_item) {

				this.active_assessment_item.rpc_ratings.forEach(rating => {
					if (rating.rater_id == this.userService.getLoggedInUser().team_leader) {
						draw_top = rating.rating >= this.TRIANGLE_SECTOR_COLOR_CUTOFF;
					}
				});

			} else if (this.active_module_boundary_index > -1) {

				var sum_ratings = 0;
				var num_ratings = 0;
				for (var assessment_item_index = 0; assessment_item_index < this.assessment_items_flat.length; assessment_item_index ++) {
					let item = this.assessment_items_flat[assessment_item_index];
					if (item.module_id != this.active_module_id) continue;
					if (item.is_module_boundary) continue;
					let _ratings = new Array<any>();
					item.rpc_ratings.forEach(rating => {
						 if (rating.rater_id == this.userService.getLoggedInUser().team_leader) _ratings.push(rating);
					});
					if (_ratings.length > 0) {
						sum_ratings += _ratings[_ratings.length - 1].rating;
						num_ratings ++;
					}
				}
				if (num_ratings > 0 && sum_ratings / num_ratings >= this.TRIANGLE_SECTOR_COLOR_CUTOFF) draw_top = true;

			}

		}
		if (draw_top) {
			ctx_outer.beginPath();
			if (this.view_type == "module") {
				ctx_outer.moveTo(center_x, outer_triangle_top_y);
				ctx_outer.lineTo(center_x - outer_triangle_delta_x / 2 + inner_triangle_correction, this.canvas_height / 2);
				ctx_outer.lineTo(center_x + outer_triangle_delta_x / 2 - inner_triangle_correction, this.canvas_height / 2);
				ctx_outer.lineTo(center_x, outer_triangle_top_y);
			} else if (this.view_type == "rpc") {
				ctx_outer.moveTo(center_x - outer_triangle_delta_x, outer_triangle_bottom_y);
				ctx_outer.lineTo(center_x - outer_triangle_delta_x / 2 + inner_triangle_correction, this.canvas_height / 2);
				ctx_outer.lineTo(center_x, outer_triangle_bottom_y - inner_triangle_correction);
				ctx_outer.lineTo(center_x - outer_triangle_delta_x, outer_triangle_bottom_y);
			}
			ctx_outer.fillStyle = this.TRIANGLE_SECTOR_FILL_COLOR;
			ctx_outer.fill();
		}
		
	}

	private on_view_type_change(): void {

		this.animation_cycle_index ++;
		this.rating_animation_index = -1;

		this.assessment_items_flat.forEach(item => {
			item.self_ratings = [];
			item.rpc_ratings = [];
			item.current_rating = 0;
		});

		if (this.view_type == "module") {
			this.animate_ratings_module(this.animation_cycle_index);
		} else if (this.view_type == "rpc") {
			this.animate_ratings_rpc(this.animation_cycle_index);
		}

		this.active_assessment_item = null;
		this.active_personality_facet = null;
		this.draw_prism();
	}

	private animate_ratings_module(_animation_cycle_index: number): void {

		if (_animation_cycle_index != this.animation_cycle_index) return;

		this.draw_prism();

		this.rating_animation_index ++;
		if (this.rating_animation_index >= this.ratings.length) return;

		let rating = this.ratings[this.rating_animation_index];

		for (var item_index = 0; item_index < this.assessment_items_flat.length; item_index ++) {
			let item = this.assessment_items_flat[item_index];
			if (item.id == rating.assessment_item_id) {
				if (rating.user_id != rating.rater_id) {
					item.rpc_ratings.push(rating);
				} else {
					item.self_ratings.push(rating);
					item.current_rating = this.compute_average_rating(item.self_ratings, AppSettings.SELF_MOVING_AVERAGE_CONSTANT);
				}
			}
		}
		setTimeout(() => {this.animate_ratings_module(_animation_cycle_index)}, this.ANIMATION_DELAY);
	}

	private animate_ratings_rpc(_animation_cycle_index: number): void {

		if (_animation_cycle_index != this.animation_cycle_index) return;

		this.draw_prism();

		this.rating_animation_index ++;
		if (this.rating_animation_index >= this.ratings.length) return;

		let rating = this.ratings[this.rating_animation_index];

		for (var item_index = 0; item_index < this.assessment_items_flat.length; item_index ++) {
			let item = this.assessment_items_flat[item_index];
			if (item.id == rating.assessment_item_id) {
				if (rating.user_id == rating.rater_id) {
					item.self_ratings.push(rating);
				} else {
					item.rpc_ratings.push(rating);
					item.current_rating = this.compute_average_rating(item.rpc_ratings, AppSettings.RPC_MOVING_AVERAGE_CONSTANT);
				}
			}
		}
		setTimeout(() => {this.animate_ratings_rpc(_animation_cycle_index)}, this.ANIMATION_DELAY);
	}

	private buy_test(): void {
		window.open("http://cre-assess.com/purchase/ilc5rzii68skvay5m4rvf3w4wy46xp-zcy78");
	}

}


