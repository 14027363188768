import { 
	Component, 
	ElementRef, 
	OnDestroy, 
	OnInit, 
	ViewChild, 
} from '@angular/core';

import { Router } from '@angular/router'

import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

import { FileSelectDirective, FileUploader } from 'ng2-file-upload/ng2-file-upload';

import { AppSettings } from './app-settings';

import { UserService } from './services/user.service';

@Component({
	selector: 'account',
	templateUrl: './account.component.html',
	styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnDestroy, OnInit {

	private APP_SETTINGS = AppSettings;

	private fname: string;
	private fname_control = new FormControl('', [Validators.required]);
	private lname: string;
	private lname_control = new FormControl('', [Validators.required]);
	private email: string;
	private email_control = new FormControl('', [Validators.required, Validators.email]);
	private phone: string;
	private phone_control = new FormControl('', [Validators.required]);
	private editAccountForm = new FormGroup({
		fname: this.fname_control, 
		lname: this.lname_control, 
		email: this.email_control, 
		phone: this.phone_control, 
	});

	private timezone: string;
	private call_availability: Array<any>;

	private password1_control = new FormControl('', [Validators.required, Validators.minLength(8)]);
	private passwordMatchValidator(): ValidatorFn {
		return (password2_control: FormControl) => {
			if (this.password1_control.value == password2_control.value) {
				return null;
			} else {
				return {matches: {dummy: true}};
			}
		}
	}
	private password2_control = new FormControl('', [Validators.required, Validators.minLength(8), this.passwordMatchValidator()]);
	private changePasswordForm = new FormGroup({
		password1: this.password1_control, 
		password2: this.password2_control
	});
	private hide_password = true;

	private waitingForServerResponse = false;

	@ViewChild('fileInput_profilePhoto', {static: true}) fileInput_profilePhoto: ElementRef;

	private uploadInterval_profilePhoto: any;
	public uploadProgress_profilePhoto: number = 0;

	private uploader_profilePhoto: FileUploader = new FileUploader({
		url: AppSettings.API_ENDPOINT + "upload-profile-photo", 
		autoUpload: true
	});

	constructor(
		private router: Router, 
		private userService: UserService
	) {
		if (this.userService.getLoggedInUser() == null) {
			this.router.navigate(["/"]);
			return;
		}
	}

	ngOnInit(): void {
		this.uploadInterval_profilePhoto = setInterval(() => {
			if (this.uploader_profilePhoto.queue.length) {
				this.uploadProgress_profilePhoto = this.uploader_profilePhoto.queue[0].progress;
			} else {
				this.uploadProgress_profilePhoto = 0;
			}
	    }, 500);

		this.uploader_profilePhoto.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
	    	response = JSON.parse(response);
	    	if (response.success) {
	    		this.userService.getLoggedInUser().photo_filename = response.photo_filename;
	    		this.userService.getLoggedInUser().thumbnail_filename = response.thumbnail_filename;
	    	} else {
	    		alert(response.message);
	    	}
			this.uploader_profilePhoto.queue[0].remove();
			this.uploadProgress_profilePhoto = 0;
	    };

	    this.fname = this.userService.getLoggedInUser().fname;
	    this.lname = this.userService.getLoggedInUser().lname;
	    this.email = this.userService.getLoggedInUser().email;
	    this.phone = this.userService.getLoggedInUser().phone;

	    this.timezone = this.userService.getLoggedInUser().timezone;
	    this.call_availability = this.userService.getLoggedInUser().call_availability;
	}

	ngOnDestroy(): void {
		if (this.uploadInterval_profilePhoto) clearInterval(this.uploadInterval_profilePhoto);
	}

	private getErrorMessage_fname(): string {
		return this.fname_control.hasError('required') ? 'You must enter a value' : '';
	}
	private getErrorMessage_lname(): string {
		return this.lname_control.hasError('required') ? 'You must enter a value' : '';
	}
	private getErrorMessage_email(): string {
		return this.email_control.hasError('required') ? 'You must enter a value' : this.email_control.hasError('email') ? 'Not a valid email' : '';
	}
	private getErrorMessage_phone(): string {
		return this.phone_control.hasError('required') ? 'You must enter a value' : '';
	}
	private getErrorMessage_password1(): string {
		return this.password1_control.hasError('required') ? 'You must enter a value' : this.password1_control.hasError('minlength') ? 'Must be at least 8 characters' : '';
	}
	private getErrorMessage_password2(): string {
		return this.password2_control.hasError('required') ? 'You must enter a value' : this.password2_control.hasError('minlength') ? 'Must be at least 8 characters' : this.password2_control.hasError('matches') ? "Passwords do not match!" : '';
	}

	private add_time_range(dow: number): void {
		this.call_availability[dow].push({start_time: "9:00 am", end_time: "10:00 am"});
		this.save_user_details(false);
	}

	private delete_time_range(dow: number, time_range_index: number): void {
		this.call_availability[dow].splice(time_range_index, 1);
		this.save_user_details(false);
	}

	private start_time_changed($event: string, dow: number, time_range_index: number): void {
		this.call_availability[dow][time_range_index].start_time = $event;
		this.save_user_details(false);
	}

	private end_time_changed($event: string, dow: number, time_range_index: number): void {
		this.call_availability[dow][time_range_index].end_time = $event;
		this.save_user_details(false);
	}

	private save_user_details(display_alert: boolean): void {
		this.waitingForServerResponse = true;
		this.userService.editLoggedInUser(this.fname, this.lname, this.email, this.phone, this.timezone, this.call_availability).subscribe(
			user => {
				this.waitingForServerResponse = false;
				if (display_alert) alert("Your changes have been saved.");
			}, 
			error => {
				this.waitingForServerResponse = false;
			}
		);
	}

	private submitChangePasswordForm(): void {
		this.waitingForServerResponse = true;
		this.userService.setPasswordForLoggedInUser(this.password1_control.value).subscribe(
			res => {
				this.waitingForServerResponse = false;
				alert("Your changes have been saved.");
			}, 
			error => {
				this.waitingForServerResponse = false;
			}
		);
	}

	private triggerFileSelect_profilePhoto() {
		this.fileInput_profilePhoto.nativeElement.click();
	}

}


