import { 
	Component, 
	HostListener, 
	OnInit
} from '@angular/core';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { LowerCasePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';

import { TestService } from './services/test.service';

@Component({
	selector: 'test',
	templateUrl: './test.component.html',
	styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {

	private personality_questions: Array<any>;
	private personality_stats: Array<any>;
	private strategy_questions: Array<any>;
	private tenure_types: Array<any>;
	private improvement_foci: Array<any>;
	private question_index: number = 0;

	private psych_model: any;

	private y_offset = 0;

	@HostListener('window:scroll', ['$event']) 
    private onScroll(event) {
    	this.y_offset = window.pageYOffset;
    }

	constructor(
		private httpClient: HttpClient, 
		private router: Router, 
		private testService: TestService
	) {
		// Do nothing.
	}

	ngOnInit(): void {
		if (!this.testService.getInvitationKey()) this.router.navigate(["invitation-key-missing"]);

		this.testService.loadTest().subscribe(
			test => {
				this.personality_questions = test.personality_questions;
				this.personality_stats = test.personality_stats;
				this.strategy_questions = test.strategy_questions;
				this.tenure_types = test.tenure_types;
				this.improvement_foci = test.improvement_foci;
		  		this.executePreviousResponses();
			}, 
			error => {
				// Do nothing for now.
			}
		);
	}

	private executePreviousResponses(): void {
		let test_responses = this.testService.getTestResponses();
	    for (var i = 0; i < test_responses.length; i ++) {
	    	this.onAnswer(test_responses[i], false, false);
	    }
	}

	private onAnswer(answerIndex: number, recordResponse: boolean, saveToServer: boolean): void {
		this.question_index ++;
		if (recordResponse) this.testService.onResponse(answerIndex, saveToServer);
	}

	private onFocusDrop(event: CdkDragDrop<any[]>) {
		moveItemInArray(this.improvement_foci, event.previousIndex, event.currentIndex);
	}
	private onFocusSubmit(): void {
		let to_submit = new Array<number>();
		this.improvement_foci.forEach(_ => to_submit.push(_.id));
		this.testService.onFocusResponse(to_submit);

		this.router.navigate(["test-complete"]);
	}

	private handleError(error: Response | any) {
		console.error("An error occurred.", error);
	}

}


