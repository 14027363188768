import { 
	Component, 
	OnInit, 
	QueryList, 
	ViewChildren
} from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router'

import { AppSettings } from './app-settings';

import { CourseComponent } from './course.component';

import { AppComponentService } from './services/appcomponent.service';
import { CourseService } from './services/course.service';
import { UserService } from './services/user.service';

import { Course } from './models/course';

@Component({
	selector: 'explore-course',
	templateUrl: './explore-course.component.html',
	styleUrls: ['./explore-course.component.css']
})
export class ExploreCourseComponent implements OnInit {

	private APP_SETTINGS = AppSettings;

	private course: Course;

	private waitingForServerResponse = false;

	@ViewChildren('courseComponent') courseComponents: QueryList<CourseComponent>;

	constructor(
		private appComponentService: AppComponentService, 
		private route: ActivatedRoute, 
		private router: Router, 
		private courseService: CourseService, 
		private userService: UserService
	) {
		if (this.userService.getLoggedInUser() == null) {
			this.router.navigate(["/"]);
			return;
		}
	}

	ngOnInit(): void {
		this.route.params.subscribe(params => {
			var course_id = +params['id'];
			if (course_id) {
				this.courseService.getCourseAsCoach(course_id).subscribe(
					course => {
						this.course = course;
					}, 
					error => {
						// Do nothing.
					}
				);
			}
		});

		this.appComponentService.getAppComponent().setExploreCourseComponent(this);
	}

	public backBtnClicked(): void {
		// 
	}

	private onExitedCourse(event: any): void {
		this.router.navigate(['manage-courses']);
	}

	private onFinishedCourse(event: any): void {
		this.router.navigate(['manage-courses']);
	}

}


