import { 
	Component, 
	OnInit
} from '@angular/core';

import { Router } from '@angular/router'

import { UserService } from './services/user.service';

import { CourseSubscription } from './models/course-subscription';

@Component({
	selector: 'courses',
	templateUrl: './courses.component.html',
	styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit {

	private courseSubscriptions: Array<CourseSubscription>;

	constructor(
		private router: Router, 
		private userService: UserService
	) {
		if (this.userService.getLoggedInUser() == null) {
			this.router.navigate(["/"]);
			return;
		}
	}

	ngOnInit(): void {
		this.userService.getCourseSubscriptions().subscribe(
			subscriptions => {
				this.courseSubscriptions = subscriptions;
			}, 
			error => {
				// Do nothing.
			}
		);
	}

	private buy_test(): void {
		window.open("http://cre-assess.com/purchase/ilc5rzii68skvay5m4rvf3w4wy46xp-zcy78");
	}

}


