import { HttpHeaders } from "@angular/common/http";

export class AppSettings {

    public static WEB_ROOT = 'https://api.customskills.com/';
	public static API_ENDPOINT = AppSettings.WEB_ROOT + 'api/';
    public static POST_HEADERS = new HttpHeaders().set('Content-Type', 'application/json');

    public static PHOTO_PATH = AppSettings.WEB_ROOT + "photos/";
    public static THUMBNAIL_PATH = AppSettings.PHOTO_PATH + "thumbnails/";
    public static USER_VIDEO_ORIG_PATH = AppSettings.WEB_ROOT + "videos_orig/";
    public static USER_VIDEO_PATH = AppSettings.WEB_ROOT + "videos/";

    public static SELF_MOVING_AVERAGE_CONSTANT = 1;
    public static RPC_MOVING_AVERAGE_CONSTANT = 1;

    public static DAYS_OF_WEEK = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    public static getColorForRating(rating: number): string {

        if (rating <= 0) return "transparent";

        // let red = ("0" + Math.round((10 - rating) / 9 * 150).toString(16)).slice(-2);
        // let green = ("0" + Math.round(Math.pow((rating - 1) / 9, 0.3) * 255).toString(16)).slice(-2);
        // let blue = "80";

        let red = ("0" + Math.round((10 - rating) / 9 * 200 + 55).toString(16)).slice(-2);
        let green = ("0" + (255).toString(16)).slice(-2);
        let blue = ("0" + Math.round((10 - rating) / 9 * 200 + 55).toString(16)).slice(-2);

        return "#" + red + green + blue;
    }

    public static getCSSClassForFacet(facet: any): string {
        if (facet.score == 0) return "blue_background";

        if (facet.slider_value < 0.333) {
            return "result_bad_bg";
        } else if (facet.slider_value <= 0.666) {
            return "result_ok_bg";
        } else {
            return "result_good_bg";
        }
    }
    public static getColorForFacet(facet: any): string {
        if (facet.score == 0) return "#9BD0FD";

        if (facet.slider_value < 0.333) {
            return "#E06000";
        } else if (facet.slider_value <= 0.666) {
            return "yellow";
        } else {
            return "limegreen";
        }
    }

    public static roundToOneDecimal(x: number): number {
        return Math.round(x * 10) / 10;
    }

}


