import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';

import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';

import { FileSelectDirective } from 'ng2-file-upload';

import { DndListModule } from 'ngx-drag-and-drop-lists';

import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { AppComponentService } from './services/appcomponent.service';
import { AssessmentItemConfigService } from './services/assessment-item-config.service';
import { CourseService } from './services/course.service';
import { ModuleService } from './services/module.service';
import { PromptsService } from './services/prompts.service';
import { PurchaseService } from './services/purchase.service';
import { RatingService } from './services/rating.service';
import { TeamService } from './services/team.service';
import { TestService } from './services/test.service';
import { UserService } from './services/user.service';

import { AccountComponent } from './account.component';
import { AppComponent } from './app.component';
import { ConfigureCourseComponent } from './configure-course.component';
import { CourseComponent } from './course.component';
import { CoursesComponent } from './courses.component';
import { DashboardComponent } from './dashboard.component';
import { DoCourseComponent } from './do-course.component';
import { ExploreCourseComponent } from './explore-course.component';
import { GetCoachingComponent } from './get-coaching.component';
import { InvitationKeyMissingComponent } from './invitation-key-missing.component';
import { LeaderboardComponent } from './leaderboard.component';
import { LeaderboardNewComponent } from './leaderboard-new.component';
import { LogInComponent } from './log-in.component';
import { ManageTeamComponent } from './manage-team.component';
import { ManageTeamsComponent } from './manage-teams.component';
import { ModulesComponent } from './modules.component';
import { QueuedItemsComponent } from './queued-items.component';
import { RatingHistoryComponent } from './rating-history.component';
import { RatingHistoryGraphComponent } from './rating-history-graph.component';
import { ReportComponent } from './report.component';
import { SettingsComponent } from './settings.component';
import { SignUpComponent } from './sign-up.component';
import { SliderComponent } from './slider.component';
import { StaticSliderComponent } from './slider-static.component';
import { StripeCheckoutComponent } from './stripe-checkout.component';
import { SVNComponent } from './svn.component';
import { TestComponent } from './test.component';
import { TestCompleteComponent } from './test-complete.component';
import { TestIntroComponent } from './test-intro.component';
import { WelcomeComponent } from './welcome.component';

@NgModule({
	declarations: [
		AccountComponent, 
		AppComponent, 
		ConfigureCourseComponent, 
		CourseComponent, 
		CoursesComponent, 
		DashboardComponent, 
		DoCourseComponent, 
		ExploreCourseComponent, 
		FileSelectDirective, 
		GetCoachingComponent, 
		InvitationKeyMissingComponent, 
		LeaderboardComponent, 
		LeaderboardNewComponent, 
		LogInComponent, 
		ManageTeamComponent, 
		ManageTeamsComponent, 
		ModulesComponent, 
		QueuedItemsComponent, 
		RatingHistoryComponent, 
		RatingHistoryGraphComponent, 
		ReportComponent, 
		SettingsComponent, 
		SignUpComponent, 
		SliderComponent, 
		StaticSliderComponent, 
		StripeCheckoutComponent, 
		SVNComponent, 
		TestComponent, 
		TestCompleteComponent, 
		TestIntroComponent, 
		WelcomeComponent
	],
	imports: [
		AppRoutingModule, 
		BrowserModule, 
		BrowserAnimationsModule, 
		DndListModule, 
		DragDropModule, 
		FormsModule, 
		HttpClientModule, 
		MatButtonModule, 
		MatCheckboxModule, 
		MatDatepickerModule, 
		MatFormFieldModule, 
		MatIconModule, 
		MatInputModule, 
		MatListModule, 
		MatMenuModule, 
		MatNativeDateModule, 
		MatProgressBarModule, 
		MatRadioModule, 
		MatSelectModule, 
		MatSlideToggleModule, 
		MatSortModule, 
		NgxMaterialTimepickerModule, 
		ReactiveFormsModule
	],
	providers: [
		AppComponentService, 
		AssessmentItemConfigService, 
		CourseService, 
		ModuleService, 
		PromptsService, 
		PurchaseService, 
		RatingService, 
		TeamService, 
		TestService, 
		UserService, 
		{provide: LocationStrategy, useClass: HashLocationStrategy}, 
	],
	bootstrap: [
		AppComponent
	]
})
export class AppModule { }


