import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppSettings } from '../app-settings';

import { Module } from '../models/module';

@Injectable()
export class ModuleService {

	constructor(
		private httpClient: HttpClient
	) { }

	createModuleObject(src: any): Module {
		if (src) {
			let m = new Module(
				src.id, 
				src.name, 
				src.topics, 
				src.is_empty, 
			);
			return m;
		} else {
			return null;
		}
	}

	getModules(): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "get-modules", 
			{withCredentials: true}
		).pipe(
			map(res => {
				if ((res as any).success) {
					let module_data = (res as any).modules;
					let prompt_tag_data = (res as any).prompt_tags;

					let modules = new Array<Module>();
					for (var i = 0; i < module_data.length; i ++) {
						let module = this.createModuleObject(module_data[i]);

						for (var j = 0; j < module.assessment_items_flat.length; j ++) {
							let assessment_item = module.assessment_items_flat[j];
							assessment_item.has_strategy_tag = false;
							assessment_item.has_motivation_tag = false;

							for (var k = 0; k < prompt_tag_data.length; k ++) {
								let prompt_tag = prompt_tag_data[k];
								if (prompt_tag.assessment_item_id == assessment_item.id && prompt_tag.tag_type == "strategy") assessment_item.has_strategy_tag = true;
								if (prompt_tag.assessment_item_id == assessment_item.id && prompt_tag.tag_type == "motivation") assessment_item.has_motivation_tag = true;
							}
						}

						modules.push(module);
					}

					return modules;
				} else {
					return null;
				}
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}

}


