import { NgModule }             from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AccountComponent } from "./account.component";
import { ConfigureCourseComponent } from "./configure-course.component";
import { CoursesComponent } from "./courses.component";
import { DashboardComponent } from "./dashboard.component";
import { DoCourseComponent } from "./do-course.component";
import { ExploreCourseComponent } from "./explore-course.component";
import { GetCoachingComponent } from "./get-coaching.component";
import { InvitationKeyMissingComponent } from "./invitation-key-missing.component";
import { LeaderboardComponent } from "./leaderboard.component";
import { LogInComponent } from "./log-in.component";
import { ManageTeamComponent } from "./manage-team.component";
import { ManageTeamsComponent } from "./manage-teams.component";
import { ModulesComponent } from "./modules.component";
import { QueuedItemsComponent } from "./queued-items.component";
import { RatingHistoryGraphComponent } from "./rating-history-graph.component";
import { ReportComponent } from "./report.component";
import { SettingsComponent } from "./settings.component";
import { SignUpComponent } from "./sign-up.component";
import { SVNComponent } from "./svn.component";
import { TestComponent }  from "./test.component";
import { TestCompleteComponent } from "./test-complete.component";
import { TestIntroComponent } from "./test-intro.component";
import { WelcomeComponent }  from "./welcome.component";

const routes: Routes = [
	{path: "", component: WelcomeComponent}, 
    {path: "account", component: AccountComponent}, 
    {path: "configure-course/:id", component: ConfigureCourseComponent}, 
    {path: "courses", component: CoursesComponent}, 
    {path: "dashboard", component: DashboardComponent}, 
    {path: "do-course/:course_id", component: DoCourseComponent}, 
    {path: "do-course/:course_id/:user_id", component: DoCourseComponent}, 
    {path: "explore-course/:id", component: ExploreCourseComponent}, 
    {path: "get-coaching", component: GetCoachingComponent}, 
    {path: "invitation-key-missing", component: InvitationKeyMissingComponent}, 
    {path: "leaderboard/:team_id/:course_id", component: LeaderboardComponent}, 
    {path: "log-in", component: LogInComponent}, 
    {path: "manage-team/:id", component: ManageTeamComponent}, 
    {path: "manage-teams", component: ManageTeamsComponent}, 
    {path: "modules", component: ModulesComponent}, 
    {path: "queued-items", component: QueuedItemsComponent}, 
    // {path: "rating-history-graph/:user_id/:assessment_item_id", component: RatingHistoryGraphComponent}, 
    {path: "report", component: ReportComponent}, 
    {path: "settings", component: SettingsComponent}, 
    {path: "sign-up", component: SignUpComponent}, 
    {path: "sign-up/:team_invitation_key", component: SignUpComponent}, 
    {path: "svn-ea", component: SVNComponent}, 
    {path: "test", component: TestComponent}, 
    {path: "test-complete", component: TestCompleteComponent}, 
    {path: "test-intro", component: TestIntroComponent}, 
];

@NgModule({
    imports: [ RouterModule.forRoot(routes, {useHash: true})],
    exports: [ RouterModule ]
})
export class AppRoutingModule {}


