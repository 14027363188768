import { 
	Component, 
	EventEmitter, 
	Input, 
	Output, 
} from '@angular/core';

import { trigger, state, style, animate, transition } from '@angular/animations';

import { AppSettings } from './app-settings';

@Component({
	selector: 'slider',
	templateUrl: './slider.component.html',
	styleUrls: ['./slider.component.css'], 
	animations: [
	    trigger('valueState', [
			state('filled_1', style({
				opacity: 1.0
			})), 
			state('filled_2', style({
				opacity: 1.0
			})),
			state('filled_3', style({
				opacity: 1.0
			})),
			state('filled_4', style({
				opacity: 1.0
			})),
			state('filled_5', style({
				opacity: 1.0
			})),
			state('filled_6', style({
				opacity: 1.0
			})),
			state('filled_7', style({
				opacity: 1.0
			})),
			state('filled_8', style({
				opacity: 1.0
			})),
			state('filled_9', style({
				opacity: 1.0
			})),
			state('filled_10', style({
				opacity: 1.0
			})),
			state('open', style({
				opacity: 0.0
			})), 
			state('void', style({
				opacity: 0.0
			})), 
			transition('open => filled_1', animate('100ms ease-in')), 
			transition('open => filled_2', animate('200ms ease-in')), 
			transition('open => filled_3', animate('300ms ease-in')), 
			transition('open => filled_4', animate('400ms ease-in')), 
			transition('open => filled_5', animate('500ms ease-in')), 
			transition('open => filled_6', animate('600ms ease-in')), 
			transition('open => filled_7', animate('700ms ease-in')), 
			transition('open => filled_8', animate('800ms ease-in')), 
			transition('open => filled_9', animate('900ms ease-in')), 
			transition('open => filled_10', animate('1000ms ease-in')), 
			transition('filled_1 => open', animate('1000ms ease-out')), 
			transition('filled_2 => open', animate('900ms ease-out')), 
			transition('filled_3 => open', animate('800ms ease-out')), 
			transition('filled_4 => open', animate('700ms ease-out')), 
			transition('filled_5 => open', animate('600ms ease-out')), 
			transition('filled_6 => open', animate('500ms ease-out')), 
			transition('filled_7 => open', animate('400ms ease-out')), 
			transition('filled_8 => open', animate('300ms ease-out')), 
			transition('filled_9 => open', animate('200ms ease-out')), 
			transition('filled_10 => open', animate('100ms ease-out')), 
	    ]), 
	]
})
export class SliderComponent {

	private APP_SETTINGS = AppSettings;

	private VALUES = [1,2,3,4,5,6,7,8,9,10];

	@Input() value: number = -1;
	@Input() editable: boolean = true;

	@Output() rated = new EventEmitter<number>();

	constructor(
		//
	) {
		//
	}

	private onRated(value: number): void {
		if (!this.editable) return;
		this.value = value;
		this.rated.emit(value);
	}

}


