import { 
	Component, 
	EventEmitter, 
	Input, 
	OnInit, 
	Output, 
	QueryList, 
	ViewChildren
} from '@angular/core';

import { trigger, state, style, animate, transition } from '@angular/animations';

// import { CourseService } from './services/course.service';
import { PromptsService } from './services/prompts.service';
import { RatingService } from './services/rating.service';
import { UserService } from './services/user.service';

import { SliderComponent } from './slider.component';

import { Course } from './models/course';
import { Rating } from './models/rating';

@Component({
	selector: 'queued-items',
	templateUrl: './queued-items.component.html',
	styleUrls: ['./queued-items.component.css'], 
	animations: [
	    trigger('descriptionState', [
			state('visible', style({height: 'auto', marginTop: '16px', marginBottom: '16px', lineHeight: '22px'})),
			state('void', style({height: '0px', marginTop: '0px', marginBottom: '0px', lineHeight: '0px'})),
			transition('void => *', animate('500ms ease-out')), 
			transition('* => void', animate('500ms ease-in'))
	    ]),  
	]
})
export class QueuedItemsComponent {

	private queuedItems: Array<any>;

	constructor(
		private promptsService: PromptsService, 
		private ratingService: RatingService, 
		private userService: UserService, 
	) {
		//
	}

	ngOnInit(): void {
		this.promptsService.getQueuedItems().subscribe(
			res => {
				this.queuedItems = res.queuedAssessmentItems;
			}, 
			error => {
				// Do nothing for now.
			}
		);
	}

	private onRated(rating: number, course_id: number, assessment_item_id: number, queued_assessment_item_id: number): void {
		this.ratingService.saveRating(new Rating(null, this.userService.getLoggedInUser().id, this.userService.getLoggedInUser().id, rating, course_id, assessment_item_id, null), queued_assessment_item_id).subscribe(
			res => {
				// Do nothing.
			}, 
			error => {
				// Do nothing.
			}
		);
	}

}


