import { 
	Component, 
	OnInit
} from '@angular/core';

import { Router } from '@angular/router'

import { UserService } from './services/user.service';

@Component({
	selector: 'get-coaching',
	templateUrl: './get-coaching.component.html',
	styleUrls: ['./get-coaching.component.css']
})
export class GetCoachingComponent implements OnInit {

	constructor(
		private router: Router, 
		private userService: UserService
	) {
		if (this.userService.getLoggedInUser() == null) {
			this.router.navigate(["/"]);
			return;
		}
	}

	ngOnInit(): void {
		// Nothing for now.
	}

	private mike(): void {
		window.location.href = 'mailto:Mike.gallegos@svn.com';
	}

	private courtney(): void {
		window.location.href = 'mailto:Courtney.dunn@svn.com';
	}

}


