import { 
	Component, 
	OnInit
} from '@angular/core';

import { Router } from '@angular/router'

import { TestService } from './services/test.service';

@Component({
	selector: 'test-complete',
	templateUrl: './test-complete.component.html',
	styleUrls: ['./test-complete.component.css']
})
export class TestCompleteComponent implements OnInit {

	constructor(
		private router: Router, 
		private testService: TestService
	) {
		// Do nothing.
	}

	ngOnInit(): void {
		// Nothing for now.
	}

}


